@tailwind base;
@tailwind components;
@tailwind utilities;


.title {
  @apply text-3xl md:text-4xl font-bold leading-snug;
}

body {
  background: #fff;
  font-family: "Maven Pro";
}
/* NavBar */

.navbar{

  --webkit-backdrop-filter: blur(5px);
}
.navbarbutton:hover i.fa-arrow-right {
  transform: translateY(-3px) rotate(-45deg);
}

.btn{
  @apply  border-2 border-color-blue gap-3 hover:bg-[#1152ad] hover:border-[#1152ad] hover:text-color-white duration-150;
}
.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-20px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-20px); }
}

/* Fetures Page */

/* .features{
  background: rgb(17,82,173);
  background: radial-gradient(circle, rgba(17,82,173,0.8520658263305322) 0%, rgba(0,212,255,1) 0%);  
} */


/* Loader */

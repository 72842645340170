.heartbeatloader {
    position: absolute;
    width: 8vmin;
    height: 8vmin;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
  }
  
  .svgdraw {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(1.4);
    z-index: 2;
  }
  
  .path {
    stroke: rgba(239, 233, 233, 0.95);
    stroke-width: 10;
    stroke-dasharray: 1000px;
    stroke-dashoffset: 1000px;
    animation: draw 2s infinite linear forwards;
  }
  
  @keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
  }
  
  /* Heart Container */
  .heart-container {
    position: absolute;
    top: 50%; /* Adjusted to move the heart up */
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    animation: heartbeat 1.5s infinite;
  }
  
  /* Heart Shape */
  .heart {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgb(255, 52, 52);
    transform: rotate(45deg);
    transform-origin: 50% 50%;
  }
  
  .heart:before,
  .heart:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(255, 52, 52);
    border-radius: 50%;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .heart:after {
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* Heartbeat Pulse Animation */
  @keyframes heartbeat {
    0%,
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
    25% {
        transform: translate(-50%, -50%) scale(1.1);
    }
    50% {
        transform: translate(-50%, -50%) scale(1.2);
    }
    75% {
        transform: translate(-50%, -50%) scale(1.1);
    }
  }
  
  